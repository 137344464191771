body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

body {
  display: inline-block;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tyler-akin-menu {
  height: 100%;
  overflow: hidden;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-around;
}

.tyler-akin-menu-links {
  list-style: none;
  padding-left: 0;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0em;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0;
  gap: 10%;
  flex-direction: column;
}

.tyler-akin-menu-link {
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 2em;
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.tyler-akin-menu-link a {
  position: relative;
  overflow: hidden;
  color: white;
  text-decoration: none;
  height: 100%;
  width: 100%;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.35s ease-in-out;
  background-color: rgba(180, 117, 42, 0.85);
  background: linear-gradient(45deg, rgba(180, 117, 42, 0.85), transparent);
  border-bottom: 3px solid white;
  border-top: 3px solid white;
}

/* .tyler-akin-menu-link a:after {
  display: inline-block;
  content: "";
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  transform: rotate(45deg);
  left: 50%;
  top: 0;
  left: 0;
  top: -50%;
  width: 200%;
  transition: opacity 0.35s ease-in-out;
  opacity: 0;
} */

.tyler-akin-menu-link:first-of-type a {
  border-top: 0;
}

.tyler-akin-menu-link:last-of-type a {
  border-bottom: 0;
}

@media screen and (min-width: 769px) {
  .tyler-akin-menu-link a {
    border-bottom: 0;
    border-top: 0;
    border-left: 3px solid white;
    border-right: 3px solid white;
  }

  .tyler-akin-menu-link:first-of-type a {
    border-left: 0;
  }

  .tyler-akin-menu-link:last-of-type a {
    border-right: 0;
  }
}
.tyler-akin-menu-link a:focus,
.tyler-akin-menu-link a:hover {
  background-color: rgba(221, 137, 36, 0.43);
}

/* .tyler-akin-menu-link a:hover:after,
.tyler-akin-menu-link a:focus:after {
  opacity: 1;
} */

@media screen and (max-width: 768px) {
  .tyler-akin-menu-links .tyler-akin-menu-link {
    height: 15vh;
    width: 100%;
  }
}
@media screen and (min-width: 769px) {
  .tyler-akin-menu-links {
    flex-direction: row;
  }
}

.tyler-akin-menu:after {
  background: linear-gradient(
    to bottom right,
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 1)
  );
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.tyler-akin-menu img {
  position: absolute;
  transition: transform 0.35s ease-in-out;
  width: 200%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  opacity: 0.75;
  z-index: -1;
}

.tyler-akin-quote {
  color: white;
  font-size: 8em;
  line-height: 0.8;
  position: absolute;
  left: 0%;
  top: 25%;
  text-align: right;
  margin-left: 2em;
}
